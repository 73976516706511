<template>
  <ContentBlock>
    <div class="top">
      <b>资质审核表<span v-if="isShowHead">企业信息填写完成后可点击 <a type="primary" :underline="false" class="ML5"  @click.self="handleDownload">下载资质审核表</a>，加盖公章后上传，如有已盖章公函图片，可直接上传</span></b>
      <template v-if="!isDisabled">
        <div class="btnContainer" v-if="isEditing">
          <el-button type="default" size="mini"  @click="handleCancel" v-if="!isShowHead">取消</el-button>
          <el-button type="primary" size="mini" @click="handleSave" v-if="!isShowHead">保存</el-button>
          <el-button type="primary" size="mini" @click="handleUpdate" v-else>保存</el-button>
        </div>
        <div class="btnContainer" v-else>
          <el-button type="primary" plain icon="el-icon-edit" size="mini" @click="handleEdit">编辑</el-button>
        </div>
      </template>
    </div>
    <el-descriptions :colon="false" :column="2" style="margin-top: 10px;" v-if="!isEditing">
      <el-descriptions-item label="推荐人电话" :span="2" labelClassName="warningLabel" contentClassName="warningContent">
        {{ form.phoneNumber }}
      </el-descriptions-item>
      <el-descriptions-item label="附件信息" :span="2" labelClassName="warningLabel" contentClassName="warningContent">
        <div v-for="img in form.tenantFileReqDTOs" :key="img.url" class="fileContainer">
          <i class="el-icon-document" style="color:#FF5959;margin-right:4px;"></i>
          <el-link type="primary" :href="img.url" :underline="false" target="blank"> {{ img.name }}</el-link>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <el-form label-position="right" ref="JZForm" v-else  :rules="formRules" :model='form'  label-width="150px" class="brand-form">
      <el-row>
        <el-col :span="12" >
          <el-form-item label="推荐人电话" prop='phoneNumber'>
            <el-input v-model="form.phoneNumber" placeholder="请输入推荐人电话" maxlength="11" show-word-limit ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" >
          <el-form-item label="资质审核表" prop='tenantFileReqDTOs'>
            <Upload
              v-model="form.tenantFileReqDTOs"
              label="资质审核表"
              buttonText="上传"
              type="single"
              :show-file-list="false"
              @success="handleUploadFileSuccess"
              @remove="handleRemove"
              accept="image/png,image/jpeg,image/jpg,application/pdf,image/bmp"
              :maxSize="10"
              size="origin"
              :limit="10"
            >
            </Upload>
          </el-form-item>
        </el-col>
        <el-col :span="24"  v-if="form.tenantFileReqDTOs&&form.tenantFileReqDTOs.length">
          <el-form-item label="">
            <ul class="imgContainer">
              <li v-for="(img,index) in form.tenantFileReqDTOs" :key="img.url">
                <div class="left">
                  <el-link type="primary" :href="img.url" :underline="false" target="blank">
                    <span class="name" :title='img.name'><img :src="require('@/assets/pdf.svg')" />{{ img.name }}</span>
                  </el-link>
                </div>
                <div class="right">
                  <i class="el-icon-success" style="color:#67C23A;margin-right: 8px;font-size:16px;"></i>
                  <i class="el-icon-delete" style="color:#409EFF;font-size:16px;cursor: pointer;" @click="handleDelete(index)"></i>
                </div>
              </li>
            </ul>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </ContentBlock>
</template>

<script>
import {updateTenantInformation} from '@/api/systemSetting';
export default {
  name: '',
  components: {
    Upload: ()=>import('@/components/common/upload'),
  },
  props: {
    isShowEditing: {
      type: Boolean,
      default () {
        return false;
      },
    },
    currentInfo: {
      type: Object,
      default () {
        return {};
      },
    },
    isDisabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
    isShowHead: {
      type: Boolean,
      default () {
        return false;
      },
    },
  },
  watch: {
    isShowHead: {
      handler (val) {
        if (val) {
          this.isEditing = this.isShowHead;
        }
      },
      immediate: true,
      deep: true,
    },
    currentInfo: {
      handler (val) {
        let info = JSON.parse(JSON.stringify(val));
        this.form = {
          phoneNumber: info.phoneNumber,
          tenantFileReqDTOs: (info.tenantFileReqDTOs || []).filter(i=>i.type === '5') || [],
        };
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleRemove () {
      console.log(arguments[0]);
    },
    handleUploadFileSuccess () {
      console.log(arguments[0]);
    },
    handleUpdate () {
      this.$refs.JZForm.validate((valid) => {
        if (valid) {
          this.$emit('updateData', this.form.tenantFileReqDTOs.map(i=>({
            ...i,
            type: '5',
          })), 'tenantFileReqDTOs');
          // this.$emit('updateData', this.form.phoneNumber, 'phoneNumber');
        }
      });
    },
    handleDelete (index) {
      this.form.tenantFileReqDTOs.splice(index, 1);
    },
    handleEdit () {
      this.isEditing = true;
    },
    handleCancel () {
      this.isEditing = false;
    },
    handleSave () {
      this.$refs.JZForm.validate((valid) => {
        if (valid) {
          let currentParams = {
            dataType: '1',
            phoneNumber: this.form.phoneNumber,
            tenantFileReqDTOs: this.form.tenantFileReqDTOs.map(i=>({
              ...i,
              type: '5',
            })),
          };
          if (this.$route.name === 'CorporateCertification') {
            this.$emit('updateData', this.form.tenantFileReqDTOs.map(i=>({
              ...i,
              type: '5',
            })), 'tenantFileReqDTOs');
            // this.$emit('updateData', this.form.phoneNumber, 'phoneNumber');
          } else {
            updateTenantInformation(currentParams).then(() => {
              this.isEditing = false;
              this.$emit('updatePage');
            });
          }
        }
      });
    },
    async handleValidateForm () {
      try {
        return await this.$refs.JZForm.validate();
      } catch (error) {
        return false;
      }
    },
    handleDownload () {
      this.$emit('handleGenerate');
    },
  },
  data () {
    return {
      form: {
        tenantFileReqDTOs: [],
        phoneNumber: '',
      },
      isEditing: false,
      formRules: {
        tenantFileReqDTOs: [
          {
            required: true,
            message: '请上传资质审核表',
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.content_block{
  padding: 0 20px 20px 0;
  min-height: calc(100vh - 420px);
}
.content_block{
  padding: 0 20px 20px 20px;
  min-height: calc(100vh - 420px);
  .top{
    height: 40px;
    line-height: 40px;
    display: flex;
    b>span{
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: rgb(95, 106, 122);
      letter-spacing: 0px;
      margin-left: 10px;
      font-weight: normal;
      i{
        font-style: normal;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #FF5959;
        letter-spacing: 0;
        margin-left: 3px;
        margin-right: 3px;
      }
    }
    .btnContainer{
      flex: 1;
      text-align:right;
    }
  }
}
.navContainer{
  list-style: none;
  width: 180px;
  margin-bottom: 0;

  margin-right: 10px;
  li{
    height:40px;
    line-height: 40px;
    margin-bottom: 0;
    padding-left: 20px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #5F6A7A;
    letter-spacing: 0;
    text-align: left;
    line-height: 20px;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    &.active{
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #237FFA;
      letter-spacing: 0;
      text-align: left;
      line-height: 20px;
      background: #E2F3FE;
      position: relative;
      &::after{
        content: '';
        width:2px;
        height:20px;
        position: absolute;
        left: 0;
        top: 10px;
        background: #237FFA;
      }
    }
  }
}
.materialContent{
  display: flex;
  padding-top: 8px;
  padding-right: 10px;
  .right{
    flex: 1;
    background: #FFFFFF;
    border: 1px solid #DFE2E8;
    border-radius: 4px;
  }
}
.imgContainer{
  list-style: none;
  li{
    height: 40px;
    line-height: 20px;
    background: #F5F7FA;
    border-radius: 4px;
    display: flex;
    width:500px;
    padding: 10px 8px;
    margin-bottom: 8px;
    .left{
      flex: 1;
      display: flex;
      .el-link.el-link--primary {
        width:440px;
        display:inline-block;
        span.el-link--inner{
          width: 100%;
          span.name{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 420px;
            display:inline-block;
          }
        }
      }
    }
    img{
      width:16px;
      height:16px;
      margin-right: 4px;
    }
  }
}
/deep/ .warningLabel{
  width: 100px;
  text-align: right;
  display: inline-block;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #929AA6;
  letter-spacing: 0;
  text-align: right;
  line-height: 20px;
}
/deep/ .warningContent{
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #1F2733;
  letter-spacing: 0;
  text-align: left;
  line-height: 20px;
  display: flex;
  flex-direction: column;
}
</style>
